.page {
  /*margin-top: 100px;*/
}
.page a {
  color: #fff;
}
a.anchor {
  height: 100px;
  display: block;
}

.page h2 {
  font-family: "alegreya", Georgia, serif;
  color: #fff;
  /*text-shadow: 1px 1px rgba(0, 0, 0, 1);*/
  font-size: 46px;
  margin-bottom: 20px;
}
.page h3 {
  font-family: "alegreya", Georgia, serif;
  font-size: 34px;
  color: #fff;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 10px;
  opacity: 0.7;
}
.page p {
  font-family: "barlow";
  font-size: 23px;
  text-align: left;
}

.page em {
  font-family: "barlow italic";
}

.page .invalid-feedback {
  font-size: 100%;
  color: #900;
  text-align: left;
}
.schedule {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.schedule table {
  border: 1px dotted #fff;
  border-collapse: collapse;
  max-width: 60%;
}
.schedule td,
.schedule th {
  font-size: 18px;
  border: 1px dotted #fff;
  padding: 5px;
  text-align: left;
  width: 50%;
}
