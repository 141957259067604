.logo {
  height: 50px;
}
.top-nav {
  font-family: "barlow", sans-serif;
}
.site-title {
  font-family: "alegreya", Georgia, serif;
}
.top-nav .dropdown-item {
  background: transparent;
  color: #fff;
}
.top-nav .dropdown-item:hover {
  background: #333;
  color: #fff;
}
.top-nav .dropdown-item.active {
  background: transparent;

  color: #fff;
  font-weight: bold;
}
.dropdown-item.active::after {
  content: "\00a0\00a0\2713";
  display: inline-block;
}
.top-nav .dropdown-menu {
  background: #000;
  color: #fff;
  z-index: 1041;
}
