.section p img {
  filter: sepia(100%) hue-rotate(170deg) saturate(120%);
  opacity: 0.6;
  width: auto;
  height: 150px;
  margin-right: 20px;
  display: inline-block;
}
.section img.banner {
  filter: none;
  /*opacity: 0.8;*/
  max-width: 900px;
  width: 100%;
  margin-bottom: 50px;
}
.section {
  padding-bottom: 50px;
  border-bottom: 2px dotted rgba(255, 255, 255, 0.5);
  min-height: 82vh;
}
.section .row .form-group label {
  font-family: "alegreya", Georgia, serif;
  font-size: 30px;
  color: rgba(255, 255, 255, 0.7);
}

.section input.form-control[type="text"],
.section textarea.form-control {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: #fff;
  font-family: "barlow", sans-serif;
  font-size: 23px;
  text-align: left;
  color: #000;
}
.section textarea.form-control {
  height: 200px;
}
.section textarea.form-control:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.3);
  color: #000;
}
.section .form-text {
  color: #fff !important;
  font-family: "barlow", sans-serif;
  font-size: 18px;
  text-align: left;
  padding-bottom: 20px;
}
.section .submit-form {
  text-align: left;
}
.section .submit-button {
  background-color: rgba(255, 255, 255, 0.7) !important;
  color: #333 !important;
  font-family: "barlow", sans-serif;
  font-size: 24px;
  font-weight: bold;
  border: none;
  opacity: 1;
}
.section .submit-button:focus {
  outline: none;
}

.section .submit-button:hover {
  background-color: #fff !important;
  color: #000 !important;
  font-family: "barlow", sans-serif;
  font-size: 24px;
  font-weight: bold;
  border: none;
  opacity: 1;
}
.section sup {
  font-size: 30px;
  color: #ffffcc;
  position: relative;
  top: 2px;
}
