body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  min-height: 4000px;
  background: #c79e70;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "alegreya";
  src: url("./assets/fonts/alegreyasc-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/alegreyasc-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PT Sans Narrow";
  src: url("./assets/fonts/ptsansnarrow-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/ptsansnarrow-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "barlow";
  src: url("./assets/fonts/barlow-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/barlow-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "barlow italic";
  src: url("./assets/fonts/barlow-italic-webfont.woff2") format("woff2"),
    url("./assets/fonts/barlow-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.background {
  min-height: 4000px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background: url(./assets/images/bg2.jpg);
  opacity: 0.6;
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
