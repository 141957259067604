.dialog .modal-body,
.dialog .modal-header,
.dialog .modal-footer {
  background: #fff url(../../assets/images/bg2.jpg);
}
.dialog .modal-header {
  border-bottom-color: #533f03;
}
.dialog .modal-footer {
  border-top-color: #533f03;
}
.dialog .modal-footer .row {
  width: 100%;
}
.dialog label {
  font-weight: bold;
}
.dialog input,
.dialog textarea {
  border-color: #fff;
  background: rgba(255, 255, 255, 0.7);
  color: #000;
}
.dialog sup {
  font-size: 30px;
  color: #ffffcc;
  position: relative;
  top: 2px;
}
.dialog textarea {
  min-height: 100px;
}
.dialog .modal-footer button {
  border-color: #fff;
  background: rgba(255, 255, 255, 0.8);
  color: #666;
}

.dialog .modal-footer button:hover {
  background: rgba(255, 255, 255, 1);
  color: #333;
}
.dialog .modal-footer button:active {
  background: rgba(255, 255, 255, 1);
  color: #333;
}
.dialog .text-muted {
  color: #000 !important;
}

.dialog .invalid-feedback {
  font-size: 100%;
  color: #c00 !important;
}
